import React from "react";
import { Layout } from "@/layout";
import {
  Box,
  SEO,
  Width,
  Text,
  SidebarBanner,
} from "@/components/index";

export default function Mentorship() {
  return (
    <Layout headerType="default" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Alicyn Yaffee"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <Width pt="12.9rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box flex="2">
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Alicyn Yaffee
            </Text>
            <Text level="1" mb="1.2rem">
              Guitarist and educator
            </Text>
            <Text level="2" mt="3.6rem">
              Alicyn Yaffee is a New York City-based guitarist, vocalist and
              bassist, by way of Kelseyville, California. An artist of wide
              range and depth, she earned her BM in Jazz Studies, with a Double
              Major of Guitar and Vocals, at Cal State University in Sacramento.
              In the few years since the move to New York, she has toured the
              U.S. and Europe under her own name, as well as with Becca Stevens,
              Grammy-nominee JC Hopkins’ Biggish Band, and bassist Benny
              Rietveld (Santana ); released her first solo album, “Someone
              Else”, featuring Cindy Blackman Santana, Ronnie Foster, Jeff
              Cressman; and played numerous venues with her own groups and with
              a diverse range of jazz and rock artists, including jazz trumpeter
              Bria Skonberg, Grammy nominated singer Tiana Major9, Haitian
              singer Darlene Desca, Dawn Drake’s Zapote, and Indian progressive
              rock composer Anupam Shobhakar. She has opened for artists David
              Lindley and Masta ace, been a tour assistant to David Crosby, a
              music transcriptionist for blue note recording artists , and
              teaches guitar and voice. She is currently working on her second
              solo album.
            </Text>
          </Box>
          <Box
            flex="1"
            mt={["6rem", null, 0, null]}
            ml={[0, null, "6rem", null]}
          >
            <SidebarBanner />
          </Box>
        </Box>
      </Width>
    </Layout>
  );
}
